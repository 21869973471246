//=========bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap'


//Skin (import after bootstrap so it takes precedence)
import '../../../css/app/src/custom.css'
import '../../../css/app/src/pages.css'
import '../../../css/app/src/product.css'
import '../../../css/app/src/sb-admin-2.css'


//CSS
import '@fortawesome/fontawesome-pro/css/fontawesome.min.css'
import '@fortawesome/fontawesome-pro/css/all.min.css'
import 'jquery-ui/themes/base/all.css'
import 'jquery-ui/themes/base/datepicker.css'
import 'jquery-ui/themes/base/core.css';


//DataTables
import 'datatables.net';
import 'datatables.net-bs4';
import 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.html5.min'
import './datatables-date-eu';

//jQueryUI datepicker JS
import 'jquery-ui/ui/widgets/datepicker'

//Admin panel Skin/theme
import 'metismenu/dist/metisMenu.min.css'
import 'metismenu'
import '../../skin/sb-admin-2.min'

window.JSZip = require('jszip');


/**
 * Generic JS that should be available app-wide
 */
$(document).ready(function () {


    /**
     * Init the menu
     */
    $("#asd").metisMenu({
        triggerElement: '.nav-link',
        parentTrigger: '.nav-item',
        subMenu: '.nav.flex-column',
        preventDefault: false
    });

    /**
     * Activate all datatables
     *
     *
     * Options
     * data-dt-filter="0,1,2,3" to specify columns to be filtered
     *
     */
    $('table[data-toggle="datatable"]').dataTable({
        // initComplete: function () {
        //     //====Support the data-dt-filter option
        //     var filter_columns = $(this).attr("data-dt-filter");
        //
        //     if (filter_columns != undefined) {
        //         this.api().columns(filter_columns).every(function () {
        //             var column = this;
        //             var select = $('<select><option value=""></option></select>')
        //                 .appendTo($(column.footer()).empty())
        //                 .on('change', function () {
        //                     var val = $.fn.dataTable.util.escapeRegex(
        //                         $(this).val()
        //                     );
        //
        //                     column
        //                         .search(val ? '^' + val + '$' : '', true, false)
        //                         .draw();
        //                 });
        //
        //             column.data().unique().sort().each(function (d, j) {
        //                 select.append('<option value="' + d + '">' + d + '</option>')
        //             });
        //         });
        //     }
        // }
    });


    /**
     * Enable datepickers
     */
    $('[data-toggle="datePicker"]').datepicker({
        changeMonth: true,
        changeYear: true,
        dateFormat: "dd-mm-yy"
    })

    /**
     * Activate all JSONSearches
     * <input
     * type="text"
     * id="value-input"
     * data-toggle="jsonSearch"
     * data-target="#key-input"
     * data-list-selector="data.events"
     * data-key-selector="event.event_id"
     * data-value-selector="event.name"
     *
     */
    // $('input[data-toggle="jsonSearch"]').jsonSearch({});


    /**
     * Enable tooltips
     */
    $('[data-toggle="tooltip"]').tooltip({});

    /**
     * Hide menu tooltips after clicking a menu item
     */
    $('nav a').click(function () {
        $('[data-toggle="tooltip"]').tooltip("hide");
    });


    /**
     * Whenever the order-modal is shown, check to see if we can automatically fetch a dataContianer to fill it
     */
    $('#g_orderProduct').on('show.bs.modal', function (event) {
        var modal = $(this);

        console.log(event);
        if (event.relatedTarget != undefined) {
            fillModal(modal, event.relatedTarget);
        }
    });


    /**
     * Anywhere in the document, if an addToCartBtn is clicked, show the modal
     */
    $(document).on("click", ".addToCartBtn", function () {
        console.log("addToCartBtn Clicked");

        var btn = $(this);
        console.log(btn);

        //display the modal itself
        $("#g_orderProduct").modal("show", btn);
    });


    /**
     * If the dates are modified
     */
    $("#g_orderProduct_date_delivery, #g_orderProduct_date_return").on("change", function () {
        var delivery = $("#g_orderProduct_date_delivery").val();
        var pickup = $("#g_orderProduct_date_return").val();
        console.log("d=" + delivery);
        console.log("p=" + pickup);
        if (delivery !== "" && pickup !== "") {

            var pid = $("#g_orderProduct_product_id").val();

            $.getJSON("/products/get-stock/" + pid + "/" + delivery + "/" + pickup + ".json", function (response) {
                console.log(response);
                if (response.code == 200) {
                    $("#g_orderProduct_stock").html(response.data);
                } else {
                    notify(response.message, "error");
                }

            });


            console.log("GET STOCK!")
        }
    });


});


/**
 * Create a temporary alert in the top of the screen with the corresponding style
 * @param message
 * @param style succes, warning, danger, info, primary
 */
function notify(message, style) {
    console.log("NOTIFY: " + message);
    alert = $("<div></div>")
        .addClass("alert")
        .addClass("alert-" + style)
        .html(message)
        .prependTo("#flashWrapper");
    if (style == "success") {
        alert.delay(2500).fadeOut();
    } else {
        alert.delay(5000).fadeOut();
    }
}

window.notify = notify;

/**
 * @version 3.0.0
 *
 * Fills the OrderModal ({@code modal} using a DOM element (e.g. addToCartButton_ ({@code dataContainer}
 *
 *
 * @param modal
 * @param dataContainer a DOM element that has the data interface for all relevant things
 * > product-name
 * > price
 * > minstock
 * > stock
 * > orderQty
 */
function fillModal(modal, dataContainer) {
    //when we are asked to fill the modal
    cleanModal(modal);

    //get the data-container as a jQuery object
    dataContainer = $(dataContainer);
    //extract
    var product_id = dataContainer.data("product-id");
    //whether the product is a loan product or not
    var productisLoan = dataContainer.data("is-loan");
    //current amount in stock
    var inStock = dataContainer.data("stock");
    //minimum stock level for the product
    var minStock = dataContainer.data("minstock");


    modal.find("#g_orderProduct_product_id").val(product_id);

    modal.find("#g_orderProduct_productName").text(dataContainer.data("product-name"));
    modal.find("#g_orderProduct_price").text(dataContainer.data("price"));


    if (minStock <= 0) {
        modal.find("#g_orderProduct_minStock").text("N.A.");
    } else {
        modal.find("#g_orderProduct_minStock").text(dataContainer.data("minstock"));
    }


    //if the product is a lona product
    if (productisLoan) {
        var loanStartDate = $("#OrderDOM").data("date-start");
        var loanEndDate = $("#OrderDOM").data("date-end");
        var isLoanOrder = $("#OrderDOM").data("is-loan");

        //if the order is already a loan order
        if (isLoanOrder) {
            //load the stock for that period
            modal.find("#g_orderProduct_stock").text("Loading");
            $.get("/products/getStock/" + product_id + "/" + loanStartDate + "/" + loanEndDate + ".json", function (data) {
                console.log(data);
                modal.find("#g_orderProduct_stock").text(Math.max(data.data, 0));
            }).fail(function () {
                modal.find("#g_orderProduct_stock").text("Error");
            });


        } else {
            //tell the user to select dates
            modal.find("#g_orderProduct_stock").text("Select Dates");
        }
    } else if (inStock == undefined) {
        modal.find("#g_orderProduct_stock").text("?");
    } else {
        modal.find("#g_orderProduct_stock").text(inStock);
    }

    //if the product is not in stock
    if (inStock == 0 && productisLoan == 0) {
        console.log("A");
        //hide order details
        $("#orderDetails").hide();

        //show ability to backorder
        $("#backorder").show();
        $("#backorderLink").prop("href", "/back-orders/add/" + product_id); //@todo: dit netjes laten genereren
    } else {
        console.log("B");
        $("#orderDetails").show();
        $("#backorder").hide();
    }

    modal.find("#g_orderProduct_orderQuantity").html(dataContainer.data("orderqty"));

    var isLoan = dataContainer.data("is-loan");


    $("#g_orderProduct_date_return").val("");
    $("#g_orderProduct_date_delivery").val("");

    if (!isLoan) {

        $("#g_orderProduct_isLoan").html("Not Loan");

        $("#g_orderProduct_date_return").prop("enabled", false);
        $("#g_orderProduct_date_return").parents(".form-group").hide();
    } else {
        $("#g_orderProduct_isLoan").html("Loan");
        $("#g_orderProduct_date_return").prop("enabled", true);
        $("#g_orderProduct_date_return").parents(".form-group").show();
    }
}


/**
 * @version 3.0.0
 *
 * @todo it appears this is not invoked, or the #'s are incorrect in the new version
 *
 * Restores the order-modal to a neutral state
 * @returns void
 */
function cleanModal(modal) {
    //TODO: alles netjes cleanen

    //$("#amount").show();
    $(modal).find("#amount").html("0");
    $(modal).find("#returnInput").css("display", "none");
    $(modal).find("#amount").html("");
    $(modal).find("#backorder").css("display", "none");
    $(modal).find("#amountGroup").css("display", "block");
    $(modal).find("#orderProductFormSubmitButton").css("display", "inline");
    $(modal).find("#inStock").html("Loading...");
    $(modal).find("#minStock").html("Loading...");
    $(modal).find("#backorder").hide();
}


/**
 * Add a global alert event-listener for errors to notify users if anything goes wrong under the hood
 */
window.addEventListener('error', function (event) {
    alert("An error has occured. An administrator has already been notified.");
});